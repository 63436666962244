<template>
  <div class="row-height">
    <div class="no-padding col-xl-12 col-lg-12 col-md-12">
      <b-card class="row-height">
        <b-row>
          <div class="col-xl-6 col-lg-6 col-sm-12">
            <h2 class="font-weight-bolder" style="color: #1B4332 ;">
              {{ $t("invoices") }}
            </h2>
          </div>
          <div class="col-xl-6 col-lg-6 col-sm-12" align="end" >
            <b-button variant="primary" to="/invoices/add-new">{{
              $t("add_new_invoice(s)")
            }}</b-button>
          </div>
          <div class="col-md-6" >
            <h6 class="font-normal">
              {{ $t("invoices_subtitle") }}
            </h6>
          </div>
        </b-row>

        <div v-if="!showPdfViwer">
          <b-col class="mb-5 mt-1 col-md-12">
            <Table
              :showFilter="true"
              hover
              :fields="fields"
              @row-clicked="myRowClickHandler"
              :url="'/invoice/list'"
            >
              <template #cell(id)="data">
                <b-link @click="goToDetails(data.item)" style="width:55px;" class="badge badge-light-primary">
                  I-{{ data.item.id }}
                </b-link>
              </template>
              <template #cell(debtor)="data">
                <span v-if="data.item.debtor != null">{{
                  data.item.debtor.name
                }}</span>
                <span v-else>-</span>
              </template>
              <template #cell(invoice_number)="data">
                <span
                  v-html="
                    data.item && data.item.invoice_number
                      ? data.item.invoice_number
                      : '-'
                  "
                >
                </span>
              </template>
              <!-- <template #cell(currency)="data">
                <span
                  v-html="
                    data.item && data.item.currency.code
                      ? data.item.currency.code
                      : '-'
                  "
                >
                </span>
              </template>
              <template #cell(amount)="data">
                <span v-if="data.item && data.item.amount">
                  {{ data.item.amount | formatAmount }}
                </span>
                <span v-else>-</span>
              </template> -->

              <template #cell(status)="data">

                <template>

                </template>

                <span class="text-success" v-if="data.value == 1">{{
                  $t("active_marketplace_offer")
                }}</span>
                <span class="text-success" v-if="data.value == 0">{{
                  $t("Open")
                }}</span>
                <span class="text-secondary" v-if="data.value == 2 || data.value == 7 ">
                    <template v-if="global_settings && global_settings.status">
                        {{ $t("rejected") }}
                    </template>
                    <template v-else>
                        {{ $t("get_offer_status") }}
                    </template>
                </span>
                <span class="text-secondary" v-if="data.value == 3">{{
                  $t("scoring_required")
                }}</span>
                <span class="text-secondary" v-if="data.value == 4">{{
                  $t("scored")
                }}</span>
                <span class="text-secondary" v-if="data.value == 5 ">{{
                  $t("click_sell_invoice")
                }}</span>
                <span class="text-secondary" v-if=" data.value == 8">{{
                  $t("soon_goes_active")
                }}</span>
                <span class="text-secondary" v-if="data.value == 6 && data.item.is_reverse != 1">{{
                  $t("debtor_confirmation_required")
                }}</span>
                <span class="text-secondary" v-if="data.value == 6 && data.item.is_reverse == 1">{{
                  $t("supplier_confirmation_required")
                }}</span>
                <span class="text-secondary" v-if="data.value == 9">{{
                  $t("in_sale")
                }}</span>
                <span class="text-secondary" v-if="data.value == 10">{{
                  $t("sold")
                }}</span>
                <span class="text-secondary" v-if="data.value == 11">{{
                  $t("settled")
                }}</span>
                <span class="text-secondary" v-if="data.value == 12">{{
                  $t("get_offer_status")
                }}</span>

              </template>
              <template #cell(custom)="data">
                <b-button
                    v-b-tooltip.hover :title="$t('read_pdf')"
                  class="mt-1 web"
                  @click="downloadPdf(data.item.id, data.item)"
                  variant="outline-success"
                  size="sm"
                >
                  <FeatherIcon icon="BookOpenIcon"></FeatherIcon>
                </b-button>
                <b-button
                v-if="data.item.status == 0  ||  data.item.status == 5"
              class="mt-1 ml-1 web"
              @click="goToDetails(data.item)"
              variant="primary"
              size="sm"
            >
            {{$t('sell')}}
            </b-button>
                <br />
              </template>
            </Table>
          </b-col>
        </div>

        <div class="row" v-if="false">
          <div class="col-md-4">
            <Table
              :showFilter="false"
              hover
              :fields="fields1"
              @row-clicked="myRowClickHandler"
              :url="'/invoice/list'"
            >
              <template #cell(id)="data">
                {{ data.item.title }}
              </template>

              <template #cell(custom)="data">
                <b-button
                  class="mt-1 mr-1"
                  @click="downloadPdf(data.item.id, data.item)"
                  variant="outline-success"
                  size="sm"
                >
                  <FeatherIcon icon="DownloadIcon"></FeatherIcon>
                </b-button>
                <b-button
                  class="mt-1"
                  @click="readPdf(data.item.id, data.item)"
                  variant="outline-success"
                  size="sm"
                >
                  <FeatherIcon icon="BookOpenIcon"></FeatherIcon>
                </b-button>
                <br />
              </template>
            </Table>
          </div>
          <div class="col-md-8">
            <embed
              class="mt-1"
              id="embed-pdf"
              :src="
                'data:application/' +
                type +
                ';headers=test' +
                item.title +
                ';base64,' +
                base64 +
                '#toolbar=0'
              "
              type="application/pdf"
              width="100%"
            />
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
import Table from "@/components/common/Table.vue";
import fileDownload from "js-file-download";
import PdfViewer from "../../views/PdfViewer.vue";
export default {
  components: { Table, PdfViewer },
  data() {
    return {
      status: "",
      showPdfViwer: false,
      type: "",
      base64: "",
      source: null,
      invoices: [],
      companies: [],
      isLoading: false,
      item: null,
      fields: [
        {
          text: "ID",
          label: "ID",
          align: "start",
          key: "id",
          sortable: true,
        },
        { label: "debtor", align: "start", key: "debtor", sortable: false },
        {
          label: "invoice_number",
          align: "start",
          key: "invoice_number",
          sortable: false,
        },
        // { label: "currency", align: "start", key: "currency", sortable: false },
        // {
        //   label: "invoice_amount",
        //   align: "start",
        //   key: "amount",
        //   sortable: true,
        // },
        { label: "status", key: "status", customSlot: true, sortable: false },
        { label: "", customSlot: true, key: "custom", class: "text-right" },
      ],
      fields1: [
        {
          text: "reference",
          label: "reference",
          align: "start",
          key: "id",
          sortable: true,
        },
        { label: "Title", customSlot: true, key: "custom", class: "" },
      ],
      global_settings: null
    };
  },
  created() {
    this.getInvoices();
    this.getCompanies();
    this.getGlobalSetting()
  },
  watch: {
    sideBar(newValue, oldValue) {
      this.showPdfViwer = false;
    },
  },
  computed: {
    sideBar() {
      return this.$store.state.app.sidebar;
    },
  },
  methods: {

    async getGlobalSetting() {
      await this.$http.post("/global_settings").then((res) => {
        if (res) {
          if(res.data.value) {
            this.global_settings = res.data.value;
            const get_offer_later = this.global_settings.filter(item => item.type === 'get_offer_later');
            this.global_settings = get_offer_later
          }
        }
      })
    },

    findCompany(item) {
      let el = this.companies.find((el) => {
        if (el.id) {
          return el.id == item.company_id;
        }
      });
      return el && el.name ? el.name : "/";
    },
    getCompanies() {
      this.$http.post("/company/admin/selectList", {}).then((response) => {
        if (response.data.status == 200) {
          this.companies = response.data.value;
        }
      });
    },
    myRowClickHandler(record, index) {
      this.$router.push("/invoice/" + record.id);
    },
    getInvoices() {
      this.load = true;
      this.$http.post("/invoice/list").then((res) => {
        if (
          typeof res.data.value.data.data != "undefined" &&
          res.data.value.data.data.length > 0
        ) {
          this.invoices = res.data.value.data.data;
        }

        this.load = false;
      });
    },
    readPdf(id, item) {
      this.$store.commit("app/ON_SIDEBAR_CLICK", false);
      this.item = item;
      this.$http
        .post("/invoice/read/" + id, {
          responseType: "json",
        })
        .then((response) => {
          this.type = "";
          this.base64 = "";

          this.base64 = response.data.value.b64;
          this.type = response.data.value.ext;
          this.$store.commit("app/SET_FILE_TYPE", this.type);
          this.$store.commit("app/SET_FILE_BASE64", this.base64);

          window.open("/read-files", "_blank");
          // this.showPdfViwer = true;
          // this.$router.push('/read-files');
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    downloadPdf(id, item) {
      this.isLoading = true;
      this.$store.commit("app/ON_SIDEBAR_CLICK", false);
      this.item = item;
      this.$http
        .post(
          "/invoice/download/" + id,
          {},
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          fileDownload(response.data, item.title);
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    goToDetails(item) {
      if (item) {
        this.$router.push("/invoice/" + item.id);
      }
    },
  },
};
</script>
<style>
</style>
